import { CartAddEntryFailEvent } from '@spartacus/cart/base/root';
import {
  EventService,
  GlobalMessageService,
  GlobalMessageType,
  TranslatableParams,
} from '@spartacus/core';
import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class RrsCartEventListenersService implements OnDestroy {
  static factory(
    rrsCartEventListeners: RrsCartEventListenersService
  ): () => void {
    const factoryFunction = (): void => {
      rrsCartEventListeners.initCartEventListeners();
    };
    return factoryFunction;
  }

  private readonly unsub$ = new Subject<void>();

  constructor(
    private readonly eventService: EventService,
    private readonly globalMessage: GlobalMessageService
  ) {}

  ngOnDestroy(): void {
    this.unsub$.next();
    this.unsub$.complete();
  }

  getParams(event: any): TranslatableParams {
    return { name: event.entry?.product?.name || '' };
  }

  initCartEventListeners(): void {
    this.eventService
      .get(CartAddEntryFailEvent)
      .pipe(takeUntil(this.unsub$))
      .subscribe((e) => {
        let message = { key: 'cart.addToCartFail' };
        const err = (e.error as HttpErrorResponse)?.error?.errors?.[0];
        if (err.type === 'InsufficientStockError') {
          message = { key: 'cart.addToCartNoMoreAvailable' };
        }

        if (
          err.type === 'CommerceCartModificationError' &&
          err.message ===
            'Product was not added to the cart. Maximum cart limit reached.'
        ) {
          message = { key: 'cart.addToCartTooManyItems' };
        }

        if (
          err.type === 'CommerceCartModificationError' &&
          err.message === 'Insufficient stock level'
        ) {
          message = { key: 'cart.addToCartNoMoreAvailable' };
        }
        this.globalMessage.add(message, GlobalMessageType.MSG_TYPE_ERROR);
      });
  }
}
