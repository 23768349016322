import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { I18nModule, UrlModule } from '@spartacus/core';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { ProductVariantsOccModule } from '@spartacus/product/variants/occ';
import { RouterModule } from '@angular/router';
import { RrsAddToCartModule } from '@app/custom/features/rrs-add-to-cart/rrs-add-to-cart.module';
import { RrsAddToWishlistModule } from '@app/custom/features/rrs-add-to-wishlist/rrs-add-to-wishlist.module';
import { RrsDeliveryOptionComponent } from '@app/custom/features/rrs-product-variants/components/rrs-delivery-option/rrs-delivery-option.component';
import { RrsDeliveryOptionsComponent } from '@app/custom/features/rrs-product-variants/components/rrs-delivery-options/rrs-delivery-options.component';
import { RrsIconModule } from '@app/shared/components';
import { RrsPickupDeliveryOptionComponent } from '@app/custom/features/rrs-product-variants/components/rrs-pickup-delivery-option/rrs-pickup-delivery-option.component';
import { RrsProductVariantsColorsComponent } from '@app/custom/features/rrs-product-variants/components/rrs-product-variants-colors/rrs-product-variants-colors.component';
import { RrsProductVariantsContainerComponent } from '@app/custom/features/rrs-product-variants/components/rrs-product-variants-container/rrs-product-variants-container.component';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { RrsStoreFinderComponentsModule } from '@app/custom/features/rrs-storefinder/rrs-storefinder-components.module';

@NgModule({
  declarations: [
    RrsProductVariantsColorsComponent,
    RrsProductVariantsContainerComponent,
    RrsDeliveryOptionComponent,
    RrsDeliveryOptionsComponent,
    RrsPickupDeliveryOptionComponent,
  ],
  exports: [RrsProductVariantsContainerComponent],
  imports: [
    CommonModule,
    FormsModule,
    I18nModule,
    NgSelectModule,
    ProductVariantsOccModule,
    ReactiveFormsModule,
    RrsAddToCartModule,
    RrsIconModule,
    UrlModule,
    RouterModule,
    RrsAddToWishlistModule,
    NgbCollapseModule,
    RrsStoreFinderComponentsModule,
  ],
})
export class RrsProductVariantsModule {}
