import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FulfillmentConfig } from '@app/custom/features/rrs-product-details/models/rrs-delivery-options.model';
import { RrsPointOfService } from '@app/custom/features/rrs-storefinder/models/rrs-store-finder.model';
import { Price } from '@spartacus/core';

@Component({
  selector: 'rrs-delivery-options',
  templateUrl: './rrs-delivery-options.component.html',
  styleUrls: ['./rrs-delivery-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsDeliveryOptionsComponent {
  @Input() productCode!: string;
  @Input() productPrice!: Price;
  @Input() fulfillment!: FulfillmentConfig;
  storeInfo!: RrsPointOfService;

  @Output() selectFulfillment = new EventEmitter<FulfillmentConfig>();
  @Output() store = new EventEmitter<RrsPointOfService | undefined | null>();
  setStoreInfo(storeInfo: RrsPointOfService): void {
    this.storeInfo = storeInfo;
    this.store.emit(this.storeInfo);
  }
  onSelectFulfillment(fulfillment: FulfillmentConfig): void {
    this.selectFulfillment.emit(fulfillment);
  }
}
