import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RrsProductScope } from '@app/spartacus/configurations/types.config';
import { CurrentProductService } from '@spartacus/storefront';
import { filter, tap } from 'rxjs/operators';
import { isNotNullable, Product, VariantOption } from '@spartacus/core';
import { Observable } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AddToCartStatus } from '@app/custom/features/rrs-add-to-cart/models/add-to-cart.model';
import {
  FulfillmentConfig,
  FulfillmentOptions,
} from '@app/custom/features/rrs-product-details/models/rrs-delivery-options.model';
import { DONATION_PRODUCT_TYPE } from '@app/custom/features/rrs-donation/models/donation.constant';
import { ActivatedRoute } from '@angular/router';
import { isInStock } from '@app/custom/features/rrs-product-details/helpers/product';
import { LittleKidSizeOrder } from '@app/custom/features/rrs-product-listing/model';
import { RrsPointOfService } from '@app/custom/features/rrs-storefinder/models/rrs-store-finder.model';
import { MyStoreServiceScope } from '@app/custom/features/rrs-product-listing/model/rrs-search-stores.model';
import { RrsStoreFacetService } from '@app/custom/features/rrs-product-listing/services/rrs-store-facet/rrs-store-facet.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-product-variants-container',
  templateUrl: './rrs-product-variants-container.component.html',
})
export class RrsProductVariantsContainerComponent {
  donationProductType = DONATION_PRODUCT_TYPE;

  storeInfo!: RrsPointOfService;
  form: UntypedFormGroup = this.fb.group({
    sizes: [''],
    width: [''],
  });

  productVariants!: VariantOption[] | undefined;
  sizes!: Map<string, VariantOption[]>;
  width!: Map<string, VariantOption[]>;
  baseProductInStockDelivery = false;
  isLittleKidProduct: boolean = false;
  oneSizeOnly: boolean = false;

  fulfillmentConfig: FulfillmentConfig = {
    selectedMethod: FulfillmentOptions.DELIVERY,
    deliveryOutOfStock: false,
    pickupOutOfStock: false,
    isPickupStoreSelected: false,
  };

  product$: Observable<Product | undefined> = this.currentProductService
    .getProduct(RrsProductScope.ALL)
    .pipe(
      filter(isNotNullable),
      tap((product) => {
        this.form.reset();
        this.isLittleKidProduct = !!product.slug?.includes('little-kid');
        this.productVariants =
          product?.variantOptions?.filter(
            (variant) =>
              (variant?.code?.length || ''.length) >= 12 &&
              (variant?.size || variant?.width)
          ) || [];
        this.setSizesVariantStructure();
        this.setWidthVariantStructure();
        this.setDefaultVariants();
        this.baseProductInStockDelivery =
          !!product.pdpAvailability?.hasShippingAvailability;
      })
    );

  constructor(
    protected currentProductService: CurrentProductService,
    protected fb: UntypedFormBuilder,
    protected route: ActivatedRoute,
    protected storeFacetService: RrsStoreFacetService
  ) {}

  get getProductCode(): string | undefined {
    return this.productVariants?.find(
      (item) =>
        item.size === this.form.get('sizes')?.value &&
        item.width === this.form.get('width')?.value
    )?.code;
  }

  originalOrder(): number {
    return 0;
  }
  setStoreInfo(storeInfo: RrsPointOfService): void {
    this.storeInfo = storeInfo;
  }
  hasWidth(value: string): boolean {
    const sizesArray = Array.from(this.sizes.entries()).find(
      (item) => item[0] === value
    )?.[1];

    const hasWidth = sizesArray?.find(
      (item) => item.width === this.form.get('width')?.value
    );

    return !isInStock(hasWidth);
  }

  hasSizes(value: string): boolean {
    const widthArray = Array.from(this.width.entries()).find(
      (item) => item[0] === value
    )?.[1];

    const hasSize = widthArray?.find(
      (item) => item.size === this.form.get('sizes')?.value
    );

    return !isInStock(hasSize);
  }

  get shouldShowSizeSelector(): boolean {
    return this.oneSizeOnly;
  }

  setSizes(value: string): void {
    if (
      this.form.get('sizes')?.value &&
      this.form.get('width')?.value &&
      this.width.size > 1
    )
      this.form.get('width')?.value
        ? this.form.get('width')?.value
        : this.form.get('width')?.setValue('M');

    this.form.get('sizes')?.value === value
      ? this.form.get('sizes')?.setValue('')
      : this.form.get('sizes')?.setValue(value);

    if (this.form.get('sizes')?.value && !this.form.get('width')?.value) {
      const [firstKey] = this.width.keys();

      if (this.hasVariantInStock(firstKey, this.form.get('sizes')?.value || ''))
        this.setWidth(firstKey);
    }
  }

  setWidth(value: string): void {
    this.form.get('width')?.setValue(value);
  }

  hasChildVariantsInStock(
    selectedVariant: string,
    parentVariant: Map<string, VariantOption[]>,
    stockType: 'shipping' | 'pickup' | 'all' = 'all'
  ): boolean {
    return !!parentVariant.get(selectedVariant)?.some(
      (variant) =>
        ({
          all: isInStock(variant),
          shipping: !!variant?.pdpAvailability?.hasShippingAvailability,
          pickup: !!variant?.pdpAvailability?.hasPickupAvailability,
        }[stockType])
    );
  }
  openFindStoresDialog(): void {
    this.storeFacetService.openSearchStoresDialog(
      MyStoreServiceScope.PICKUP_IN_STORE,
      this.getProductCode
    );
  }
  hasVariantInStock(
    selectedWidth: string,
    selectedSize: string,
    stockType: 'shipping' | 'pickup' | 'all' = 'all'
  ): boolean {
    const selectedVariant = this.productVariants?.find(
      (item) => item.size === selectedSize && item.width === selectedWidth
    );

    return {
      all: isInStock(selectedVariant),
      shipping: !!selectedVariant?.pdpAvailability?.hasShippingAvailability,
      pickup: !!selectedVariant?.pdpAvailability?.hasPickupAvailability,
    }[stockType];
  }

  statusFactory(
    disabled: boolean,
    message: string,
    openChangeStorePopup: boolean = false
  ): AddToCartStatus {
    return {
      disabled,
      message,
      openChangeStorePopup,
    };
  }

  getAddToCartStatus(): AddToCartStatus {
    const { sizes, width } = this.form.value;
    const noVariantsAvailable = !this.productVariants?.length;
    const selectedSize = sizes;
    const selectedWidth = width;
    const isVariantSelected = selectedSize && selectedWidth;
    const onlySizeSelected = selectedSize && !selectedWidth;
    const onlyWidthSelected = selectedWidth && !selectedSize;
    const noVariantsSelected = !selectedSize && !selectedWidth;

    if (noVariantsAvailable) {
      this.selectFulfillment({
        deliveryOutOfStock: !this.baseProductInStockDelivery,
      });
      return this.statusFactory(
        !this.isDeliveryInStock() && !this.isPickupInStock(),
        'rrs.addToCart.addToCart'
      );
    }

    if (noVariantsSelected) {
      this.selectFulfillment({ deliveryOutOfStock: false });
      return this.statusFactory(true, 'rrs.addToCart.selectSizeAndWidth');
    }

    if (onlySizeSelected || onlyWidthSelected) {
      const selectedVariant = onlySizeSelected ? selectedSize : selectedWidth;
      const parentVariant = onlySizeSelected ? this.sizes : this.width;
      this.selectFulfillment({
        deliveryOutOfStock: !this.hasChildVariantsInStock(
          selectedVariant,
          parentVariant,
          'shipping'
        ),
      });

      if (
        this.isPickupMethodSelected() ||
        !this.fulfillmentConfig.deliveryOutOfStock
      ) {
        return this.statusFactory(
          true,
          onlySizeSelected
            ? 'rrs.addToCart.selectWidth'
            : 'rrs.addToCart.selectSize'
        );
      }
      return this.statusFactory(true, 'rrs.addToCart.deliveryOutOfStock');
    }

    if (isVariantSelected) {
      this.selectFulfillment({
        deliveryOutOfStock: !this.hasVariantInStock(
          selectedWidth,
          selectedSize,
          'shipping'
        ),
      });
      if (this.isDeliveryInStock() || this.isPickupInStock()) {
        return this.statusFactory(false, 'rrs.addToCart.addToCart');
      }
      if (this.isDeliveryMethodSelected()) {
        return this.statusFactory(true, 'rrs.addToCart.deliveryOutOfStock');
      }
      if (this.isPickupMethodSelected()) {
        return this.statusFactory(
          false,
          this.fulfillmentConfig.isPickupStoreSelected
            ? 'rrs.product.deliveryOptions.checkNearBy'
            : 'rrs.addToCart.selectPickupStore',
          true
        );
      }
    }

    return this.statusFactory(false, 'rrs.addToCart.addToCart');
  }

  selectFulfillment(fulfillment: FulfillmentConfig): void {
    this.fulfillmentConfig = {
      ...this.fulfillmentConfig,
      ...fulfillment,
    };
  }

  isDeliveryInStock(): boolean {
    return (
      this.fulfillmentConfig.selectedMethod === FulfillmentOptions.DELIVERY &&
      !this.fulfillmentConfig.deliveryOutOfStock
    );
  }

  isDeliveryMethodSelected(): boolean {
    return (
      this.fulfillmentConfig.selectedMethod === FulfillmentOptions.DELIVERY
    );
  }

  isPickupMethodSelected(): boolean {
    return this.fulfillmentConfig.selectedMethod === FulfillmentOptions.PICKUP;
  }

  isPickupInStock(): boolean {
    return Boolean(
      this.fulfillmentConfig.selectedMethod === FulfillmentOptions.PICKUP &&
        this.fulfillmentConfig.isPickupStoreSelected &&
        !this.fulfillmentConfig.pickupOutOfStock
    );
  }

  protected setSizesVariantStructure(): void {
    const sizesVariantOptions = new Map<string, VariantOption[]>();

    const sizesOptions = this.isLittleKidProduct
      ? this.sortLittleKidSize()
      : this.productVariants?.sort((a, b) => Number(a.size) - Number(b.size)) ||
        [];

    sizesOptions?.forEach((item) => {
      const size = item.size;

      if (!sizesVariantOptions.has(size)) sizesVariantOptions.set(size, []);
      sizesVariantOptions.get(size)?.push(item);
    });

    if (sizesVariantOptions.size <= 1) {
      this.oneSizeOnly = true;
    }

    this.sizes = sizesVariantOptions;
  }

  /**
   * Sorts the little kid product sizes according to the LittleKidSizeOrder list
   *
   * @returns VariantOption[] | undefined
   */
  private sortLittleKidSize(): VariantOption[] | undefined {
    return this.productVariants?.sort((a, b) => {
      let indexA = LittleKidSizeOrder.indexOf(a.size);
      let indexB = LittleKidSizeOrder.indexOf(b.size);
      if (indexA === -1) indexA = LittleKidSizeOrder.length;
      if (indexB === -1) indexB = LittleKidSizeOrder.length;
      return indexA - indexB;
    });
  }

  protected setWidthVariantStructure(): void {
    const widthPattern = [
      'M',
      'D',
      'B',
      'W',
      '2W',
      '3W',
      '4W',
      '5W',
      'E',
      '2E',
      '3E',
      '4E',
      '5E',
      'N',
      'S',
      '2S',
      '3S',
      '4S',
      '5S',
      'A',
      '2A',
      '3A',
      '4A',
      '5A',
    ];
    const widthVariantOptions = new Map<string, VariantOption[]>();

    const widthsOptions =
      this.productVariants?.sort((a, b) => {
        if (widthPattern.indexOf(a.width) === widthPattern.indexOf(b.width)) {
          return 0;
        } else {
          return widthPattern.indexOf(a.width) > widthPattern.indexOf(b.width)
            ? 1
            : -1;
        }
      }) || [];

    widthsOptions?.forEach((item) => {
      const width = item.width;

      if (!widthVariantOptions.has(width)) widthVariantOptions.set(width, []);
      widthVariantOptions.get(width)?.push(item);
    });

    this.width = widthVariantOptions;
  }

  protected setDefaultVariants(): void {
    const [firstKey] = this.width.keys();
    const sizesFromQuery = this.route.snapshot.queryParamMap.get('sizes');
    const widthFromQuery = this.route.snapshot.queryParamMap.get('width');

    this.setWidth(widthFromQuery || firstKey);

    if (
      sizesFromQuery &&
      this.hasVariantInStock(
        this.form.get('width')?.value,
        sizesFromQuery || ''
      )
    ) {
      this.setSizes(sizesFromQuery || '');
    }

    if (this.oneSizeOnly) {
      this.setSizes('0');
    }
  }
}
