<div
  class="delivery"
  [attr.aria-label]="'rrs.product.deliveryOptions.inStorePickup' | cxTranslate">
  <div class="d-flex align-items-center flex-grow-1 h-100">
    <input
      class="form-check-input me-3 d-none"
      type="radio"
      (change)="selectDeliveryOption()"
      (keydown.enter)="selectDeliveryOption()"
      [disabled]="fulfillment.deliveryOutOfStock"
      [checked]="
        fulfillment.selectedMethod === deliveryOptions.DELIVERY &&
        !fulfillment.deliveryOutOfStock
      "
      id="deliveryOptionRadio" />
    <label
      class="form-check-label cursor-pointer h-100 d-flex align-items-start flex-grow-1 delivery__label"
      [class.opacity-50]="fulfillment.deliveryOutOfStock"
      for="deliveryOptionRadio">
      <div
        class="d-flex flex-column justify-content-between align-items-start flex-grow-1 gap-2">
        <p class="delivery__title mb-1 fw-bold d-flex gap-1 align-items-center">
          <img
            [attr.alt]="'truck-fast.svg'"
            [attr.src]="'/assets/icons/truck-fast.svg'"
            height="16"
            width="16" />
          {{ getTitleAndDescription().title | cxTranslate }}
        </p>
        <p
          class="delivery__sub_title mb-0"
          [innerHTML]="
            getTitleAndDescription().description
              | cxTranslate: { price: freeShippingThreshold.formattedValue }
          "></p>
      </div>
    </label>
  </div>
</div>
