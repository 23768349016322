<ng-container *ngIf="productCode">
  <rrs-delivery-option
    class="d-block mb-3 col-6"
    (select)="onSelectFulfillment($event)"
    [fulfillment]="fulfillment"
    [productCode]="productCode"
    [productPrice]="productPrice">
  </rrs-delivery-option>
  <rrs-pickup-delivery-option
    class="d-block mb-3 col-6"
    (select)="onSelectFulfillment($event)"
    (store)="setStoreInfo($event)"
    [fulfillment]="fulfillment"
    [productCode]="productCode"></rrs-pickup-delivery-option>
</ng-container>
